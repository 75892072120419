<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>Tipos de contenido personalizado</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs12>
        </v-flex>
        <v-flex xs12>
          <v-row 
            v-for="item in customContentTypes" 
            :key="item.ID" 
            v-bind:class="'itemDraggable'"
            style="padding: 0;margin: 0;cursor: pointer;"
          >
            <v-col
              cols="12"
              md="12"
              style="padding-left: 0;"
            >
              <a @click="handleEdit(item.ID)">{{ item.Name && item.Name.length > 0 ? item.Name : item.ID }}</a>
            </v-col>
          </v-row>
        </v-flex>
        <v-flex xs12>
          <v-btn block :elevation="0" color="primary" @click="handleAdd">
            {{ $t('common.add', locale)}}
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <custom-content-type-form
            :workspaceID="workspaceID"
            :customContentTypeID="editedID"
            :locale="locale"
            :onClose="handleClose"
          />
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import CustomContentTypeForm from './customContentTypeForm/Index'
export default {
  name: 'CustomContentType',
  components: {
    CustomContentTypeForm,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    loaded: false,
    customContentTypes: [],
    editedID: null,
    background: null,
    newBackground: null,
  }),
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData () {
      this.loading = true
      this.customContentTypes = []
      if (!this.workspaceID) return
      api.getAllWithoutLimit ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types`)
        .then(response => {
          this.customContentTypes = response
          this.loading = false
          this.loaded = true
        })
        .catch(error => {
          alert(error)
        })
      /*
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/design`)
        .then(response => {
          this.appleIcon = response.AppleIcon
          this.privateBGColor = response.PrivateBGColor
          this.btnBGColor = response.BtnBackgroundColor
          this.btnTextColor = response.BtnTextColor
          this.foodDrinkBGColor = response.FoodDrinkBGColor
          this.foodDrinkTextColor = response.FoodDrinkTextColor
          this.expandFirstElement = response.ExpandFirstElement ? true : false
          this.expandFirstElementWithoutMainMenu = response.ExpandFirstElementWithoutMainMenu ? true : false
          this.designByCards = response.DesignByCards ? true : false
          this.designByCardsLogo = response.DesignByCardsLogo
          this.designByCardsProminent = response.DesignByCardsProminent ? true : false
          this.designByCardsBarNoImage = response.DesignByCardsBarNoImage ? true : false
          this.cacheFormData = response.CacheFormData ? true : false
        })*/
    },
    handleExpand () {
      if(!this.loaded && this.workspaceID) this.handleGetData()
    },
    handleAdd () {
      this.editedID = 'new'
    },
    handleEdit (v) {
      this.editedID = v
    },
    handleClose (reload) {
      if (reload) this.handleGetData()
      this.editedID = null
    },
    handleChange () {/*
      this.onChangeData({
        AppleIcon: this.newAppleIcon,
        PrivateBGColor: this.privateBGColor,
        BtnBGColor: this.btnBGColor,
        BtnTextColor: this.btnTextColor,
        FoodDrinkBGColor: this.foodDrinkBGColor,
        FoodDrinkTextColor: this.foodDrinkTextColor,
        ExpandFirstElement: this.expandFirstElement,
        ExpandFirstElementWithoutMainMenu: this.expandFirstElementWithoutMainMenu,
        DesignByCards: this.designByCards,
        DesignByCardsLogo: this.designByCardsLogo,
        DesignByCardsLogoNewImage: this.designByCardsLogoNewImage,
        DesignByCardsProminent: this.designByCardsProminent,
        DesignByCardsBarNoImage: this.designByCardsBarNoImage,
        CacheFormData: this.cacheFormData
      })*/
    },
  },
}
</script>


